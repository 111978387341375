<template>
  <div>
    <div class="row">
      <div class="col-12">
        <vnud-card class="stacked-form card-plain">
          <form method="#" action="#" @submit.prevent>
            <span style="margin-right: 5px;">
              <label for="file-upload" class="custom-file-upload">
                <i class="fa fa-cloud-upload"></i> Select Media
              </label>
              <input style="display:none" id="file-upload" slot="default" name="eventMedia" ref="file" type="file"
                @change="handleFileUpload()" label="File" accept="image/*" />
            </span>
            <n-button class="text-white" :type="hasFile ? 'info' : 'default'" @click="uploadFile">Upload</n-button>
            <div style="height:50px;" v-show="loadProgress > 0">
              <n-progress v-if="!loaded" :value="loadProgress ? loadProgress : 0" :height="15" type="success" />
            </div>
          </form>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
// import swal from 'sweetalert2'
import { apiPostFile } from '@/utils/api'
import { Progress } from '@/components/index.js'

export default {
  components: {
    [Progress.name]: Progress
  },
  props: ['eventId'],
  data() {
    return {
      file: '',
      mediaUri: '',
      mediaType: '',
      loadProgress: 0,
      loaded: false,
      intervalId: null,
    }
  },
  computed: {
    hasFile: function () {
      return !!this.file
    }
  },
  methods: {
    handleFileUpload() {
      if(['image/png','image/jpg','image/jpeg','image/gif','image/heif', 'image/heic'].includes(this.$refs.file.files[0]['type'])) {
        this.file = this.$refs.file.files[0]
      } else {
        this.file = ''
        return this.$swal({
          title: `Please select correct formate image`,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-danger btn-fill',
          customClass: {
            container: 'custom-swal',
            title: 'mb-0',
            icon: 'question-icon mt-0',
            cancelButton: 'my-0',
            confirmButton: 'my-0'
          }
        })
      }
    },
    async uploadFile() {
      if (!this.hasFile) {
        return this.$swal({
          title: `Select a file`,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-danger btn-fill'
        })
      }
      try {
        this.intervalId = setInterval(() => { if (this.loadProgress < 90) this.loadProgress += 1 }, 100)
        let formData = new FormData()
        formData.append('eventMedia', this.file)
        formData.append('eventId', this.eventId)
        // eslint-disable-next-line no-unused-vars
        let upload = await apiPostFile('/media', formData)
        clearInterval(this.intervalId)
        this.intervalId = null
        this.loadProgress = 100

        setTimeout(() => {
          this.loaded = true
          window.location = '/events/' + this.eventId + '?section=photos'
        }, 1000)
      } catch (error) {
        clearInterval(this.intervalId)
        this.intervalId = null
        this.loadProgress = 0
        this.loaded = false
        this.$swal({
        title: 'Error!',
        text: error.response.data,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-success btn-fill',
        buttonsStyling: false,
        customClass: {
          container: 'custom-swal',
          title: 'mb-0',
          icon: 'question-icon mt-0',
          cancelButton: 'my-0',
          confirmButton: 'my-0'
        }
      })
      }
    }
  }
}
</script>
<style scoped>
.custom-file-upload {
  font-size: 14px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>
