<template>
  <navbar :show-navbar="showNavbar" id="navigation">
    <div class="navbar-wrapper">
      <div v-if="IsValidLogin" class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
        <navbar-toggle-button @click="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="">
        {{ $route.name }}
      </a>
    </div>
    <button
      @click="toggleNavbar"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <template v-slot:navbar-menu>
      <!--
      <form>
        <div class="input-group no-border">
          <fg-input placeholder="Search..." addon-right-icon="now-ui-icons ui-1_zoom-bold">
          </fg-input>
        </div>
      </form>
      -->
      <ul class="navbar-nav">
        <drop-down v-if="IsValidLogin" tag="li" position="right" class="nav-item" icon="now-ui-icons users_single-02">
          <a class="dropdown-item" href="/profile/edit">Profile</a>
          <a class="dropdown-item" href="/auth/logout">Logout</a>
        </drop-down>
        <drop-down v-if="!IsValidLogin" tag="li" position="right" class="nav-item" icon="now-ui-icons users_single-02">
          <a class="dropdown-item" href="/auth/register">Register</a>
          <a class="dropdown-item" href="/auth/login">Login</a>
        </drop-down>
      </ul>
    </template>
  </navbar>
</template>

<script>
import { Navbar, NavbarToggleButton } from '@/components/index.js';
import { userHasValidLogin } from '@/utils/auth.js'

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    IsValidLogin(){
      return userHasValidLogin()
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
}
</script>
<style></style>
