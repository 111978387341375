<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12">
        <vnud-card class="stacked-form" title="Edit Group">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title">{{ group.name }}</h4>
              <div class="d-flex align-items-center">
                <fg-input class="">
                  <el-input type="search" class="input-with-icon mr-2" clearable style="width: 200px"
                    placeholder="Search records" v-model="searchQuery" aria-controls="datatables">
                    <template #prefix>
                      <el-icon class="el-input__icon">
                        <search />
                      </el-icon>
                    </template>
                  </el-input>
                </fg-input>
                <el-tooltip content="Add Contact To Group" :open-delay="300" placement="top">
                  <n-button type="primary" class="mt-0 mr-2" round icon="" size="md"
                    style="float: right;" @click="opengContactModal">
                    <i class="now-ui-icons ui-1_simple-add text-white"></i>
                  </n-button>
                </el-tooltip>
                <!--
                <div class="">
                  <el-tooltip content="Sync Group Members" :open-delay="300" placement="top">
                    <n-button :disabled="!IsGooglUser" type="primary" class="mt-0 mr-2" round icon="" size="md"
                      style="float: right;" @click="opengSyncModal">
                      <i class="now-ui-icons arrows-1_refresh-69 text-white"></i>
                    </n-button>
                  </el-tooltip>
                </div>
                -->
                <div content="Go Back" :open-delay="300" placement="top">
                  <n-button class="mt-0" type="info" size="sm" @click="backToContactsGroups()">
                    <img class="action-button-icon" style="cursor:pointer"
                      src="@/assets/img/glyphicons-basic-309-square-empty-left.png" />
                  </n-button>
                </div>
              </div>
            </div>
          </template>
          <Alert :error-bag="customError.errorBag" class="mb-2" />
          <el-table stripe :data="queriedData" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selectable" />
            <el-table-column property="name" label="Name" width="120" />
            <el-table-column property="email" label="Email" />
            <el-table-column property="phone" label="Phone" />
          </el-table>
          <template v-slot:footer>
            <n-button @click="removeFromGroup" type="default" :disabled="contactSelectable == 0">Remove From
              Group</n-button>
            <div class="
              d-flex
              w-100
              justify-content-center justify-content-sm-between
              flex-wrap
              align-items-center
              m-0
              py-3
              contact-footer
            ">
              <div class="d-flex align-items-center">
                <span class="mr-2">Contacts per page: </span>
                <el-select class="per-page-selector" placeholder="Contacts per page" v-model="pagination.perPage">
                  <el-option v-for="option in pagination.perPageOptions" :value="option" :label="option"
                    :key="option" />
                </el-select>
              </div>
              <n-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
                :total="contacts.length">
              </n-pagination>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
    <modal :show="showAlert" class="modal-primary" :show-close="false">
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>

    <!-- Contact modal -->
    <modal v-model:show="contactModal" modalClasses="modal-lg" class="modal-primary google-contact-modal"
      :showClose="true">
      <template v-slot:header>
        <div class="modal-profile d-lg-flex justify-content-between align-items-center w-100">
          <h4 class="card-title mt-0">Contacts</h4>
          <div class="heading d-flex align-items-center flex-start justify-content-between justify-content-lg-start">
            <div>
              <fg-input class="d-flex">
                <el-input type="search" class="input-with-icon mr-10" clearable placeholder="Search records"
                  v-model="mSearchQuery" aria-controls="datatables">
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <search />
                    </el-icon>
                  </template>
                </el-input>
              </fg-input>
            </div>
          </div>
        </div>
      </template>
      <el-table stripe :data="mQueriedData" style="width: 100%" @selection-change="handleMSelectionChange">
        <el-table-column type="selection" width="55" :selectable="mselectable" />
        <el-table-column property="name" label="Name" width="120" />
        <el-table-column property="email" label="Email" />
        <el-table-column property="phone" label="Phone" />
      </el-table>
      <template v-slot:footer>
        <n-button @click="saveContactToGroup" type="default" :disabled="mContactsSelectable == 0">Save</n-button>
        <div class="
              d-flex
              w-100
              justify-content-between
              flex-wrap
              align-items-center
              m-0
              py-3
            ">
          <div class="d-flex align-items-center">
            <span class="mr-2">Contacts per page: </span>
            <el-select class="per-page-selector" placeholder="Contacts per page" v-model="mPagination.perPage">
              <el-option v-for="option in mPagination.perPageOptions" :value="option" :label="option" :key="option" />
            </el-select>
          </div>
          <n-pagination class="pagination-no-border mb-0" v-model="mPagination.currentPage"
            :per-page="mPagination.perPage" :total="mContacts.length">
          </n-pagination>
        </div>
      </template>
    </modal>

    <!--Contact Alert Modal -->
    <modal v-model:show="showAlertContact" modalClasses="modal-lg" class="modal-primary google-contact-modal"
      :showClose="true">
      <template v-slot:header>
        <div class="modal-profile d-lg-flex justify-content-between align-items-center w-100">
          <h4 class="card-title mt-0">Alert</h4>
        </div>
      </template>
      <p v-html="alertMessageContact"/>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlertContact = false">Try again</n-button>
      </template>
    </modal>

    <!-- Google Group member Sync modal -->
    <modal v-model:show="gContactModal" modalClasses="modal-lg" class="modal-primary google-contact-modal"
        :showClose="true">
        <template v-slot:header>
          <div class="modal-profile d-lg-flex justify-content-between align-items-center w-100">
            <h4 class="card-title mt-0">Google Groups Contact</h4>
            <div class="heading d-flex align-items-center flex-start justify-content-between justify-content-lg-start">
              <div>
                <fg-input class="d-flex">
                  <el-input type="search" class="input-with-icon mr-10" clearable placeholder="Search records"
                    v-model="gSearchQuery" aria-controls="datatables">
                    <template #prefix>
                      <el-icon class="el-input__icon">
                        <search />
                      </el-icon>
                    </template>
                  </el-input>
                </fg-input>
              </div>
            </div>
          </div>
        </template>
        <el-table stripe :data="gQueriedData" style="width: 100%" @selection-change="handleGSelectionChange">
          <el-table-column type="selection" width="55" :selectable="gselectable" />
          <el-table-column property="name" label="Name" width="120" />
          <el-table-column property="email" label="Email" />
          <el-table-column property="phone" label="Phone" />
        </el-table>
        <template v-slot:footer>
          <n-button @click="saveContact" type="default" :disabled="gContactSelectable == 0">Save</n-button>
          <n-button @click="saveAllContact" type="default">Save All</n-button>
          <div class="
              d-flex
              w-100
              justify-content-between
              flex-wrap
              align-items-center
              m-0
              py-3
            ">
            <div class="d-flex align-items-center">
              <span class="mr-2">Contacts per page: </span>
              <el-select class="per-page-selector" placeholder="Contacts per page" v-model="gPagination.perPage">
                <el-option v-for="option in gPagination.perPageOptions" :value="option" :label="option" :key="option" />
              </el-select>
            </div>
            <n-pagination class="pagination-no-border mb-0" v-model="gPagination.currentPage"
              :per-page="gPagination.perPage" :total="googleContact.length">
            </n-pagination>
          </div>
        </template>
      </modal>
  </div>
</template>
<script>

import {
  Modal,
  VnudCard,
  Pagination as NPagination,
} from '@/components/index.js'
import { Search } from '@element-plus/icons-vue'
import { apiGet,apiPost } from '@/utils/api'
import { isGoogleUser } from '@/utils/auth.js'

export default {
  components: {
    Modal,
    VnudCard,
    NPagination,
    Search,
  },
  inject: ["customError"],
  data() {
    return {
      id: '',
      group: {
        name: '',
        email: '',
        description: '',
      },
      showAlert: false,
      showAlertContact:false,
      alertMessageContact:'',
      searchQuery: "",
      propsToSearch: ["name"],
      selectable: (row) => ![1, 2].includes(row.id),
      contacts: [],
      contactSelectable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      mContacts: [],
      mselectable: (row) => ![1, 2].includes(row.id),
      contactModal: false,
      mSearchQuery: '',
      mContactsSelectable: [],
      mPagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      gselectable: (row) => ![1, 2].includes(row.id),
      gContactModal: false,
      googleContact: [],
      gSearchQuery: '',
      gContactSelectable: [],
      gPagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
    }
  },
  async mounted() {
    this.customError.resetErrorBag();

    this.id = this.$route.params.groupId
    this.group = await apiGet('/contactGroup/' + this.id)
    this.getGroupContacts();
  },
  computed: {
    pagedContacts() {
      return this.contacts.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedContacts;
      }
      let result = this.contacts.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.contacts.length > 0
        ? this.contacts.length
        : this.contacts.length;
    },

    /* Below computed property is for model table */
    mPagedContacts() {
      return this.mContacts.slice(this.m_from, this.m_to);
    },
    mQueriedData() {
      if (!this.mSearchQuery) {
        return this.mPagedContacts;
      }
      let result = this.mContacts.filter((row) => {
        console.log(row);
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toLowerCase();
          if (rowValue.includes && rowValue.includes(this.mSearchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result.slice(this.m_from, this.m_to);
    },
    m_total() {
      return this.mContacts.length > 0
        ? this.mContacts.length
        : this.mContacts.length;
    },
    m_to() {
      let highBound = this.m_from + this.mPagination.perPage;
      if (this.m_total < highBound) {
        highBound = this.m_total;
      }
      return highBound;
    },
    m_from() {
      return this.mPagination.perPage * (this.mPagination.currentPage - 1);
    },
    IsGooglUser() {
      return isGoogleUser()
    },
    gPagedContacts() {
      return this.googleContact.slice(this.g_from, this.g_to);
    },
    gQueriedData() {
      if (!this.gSearchQuery) {
        return this.gPagedContacts;
      }
      let result = this.googleContact.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toLowerCase();
          if (rowValue.includes && rowValue.includes(this.gSearchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.g_from, this.g_to);
    },
    g_to() {
      let highBound = this.g_from + this.gPagination.perPage;
      if (this.g_total < highBound) {
        highBound = this.g_total;
      }
      return highBound;
    },
    g_from() {
      return this.gPagination.perPage * (this.gPagination.currentPage - 1);
    },
    g_total() {
      return this.googleContact.length > 0
        ? this.googleContact.length
        : this.googleContact.length;
    },
  },
  methods: {
    async getContactGroup() {
      this.googleContact = await apiGet('/contactGroup')
    },
    opengSyncModal() {
      this.gContactModal = true
      this.syncGoogleGroupContacts();
    },
    async syncGoogleGroupContacts() {
      /*
      try {
        this.showAlert = false;
        this.loading = true;
        const response = await apiGet('contactGroup/:groupId/members');
        this.googleContact = response.contacts.map(contact => {
          const name = contact.names ? contact.names[0].displayName : 'Unknown';
          const email = contact.emailAddresses ? contact.emailAddresses[0].value : 'No Email';
          const phone = contact.phoneNumbers ? contact.phoneNumbers[0].value : 'No Phone';
          return {
            name,
            email,
            phone
          };
        });
        this.loading = false;
      } catch (error) {
        this.alertMessage = 'Error fetching contacts';
        this.showAlert = true
        this.loading = false;
        console.error('Error fetching contacts:', error);
      }
      */
    },
    async saveContact() {
      try {
        this.showAlert = false;
        let selectedContacts = this.gContactSelectable.map(contact => {
          const name = contact.name != 'Unknown' ? contact.name : null;
          const email = contact.email != 'No Email' ? contact.email : null;
          const phone = contact.phone != 'No Phone' ? contact.phone : null;
          return {
            name,
            email,
            phone
          };
        });
        const response = await apiPost('/contact/save-google-contacts', {
          contacts: selectedContacts
        });
        this.alertMessage = response.message;
        this.showAlert = true
        this.getContacts();
      } catch (error) {
        this.alertMessage = error;
        this.showAlert = true
        console.error('Error saving contacts:', error);
      }
    },
    saveAllContact() {
      this.gContactSelectable = this.googleContact;
      this.saveContact();
    },
    handleGSelectionChange(val) {
      this.gContactSelectable = val
      this.$forceUpdate()
    },
    opengContactModal() {
      this.getMContacts()
      this.contactModal = true
    },

    handleSelectionChange(val) {
      this.contactSelectable = val
      this.$forceUpdate()
    },

    async getGroupContacts() {
      try {
        this.contacts = [];
        this.contacts = await apiGet('/contactGroupMember/' + this.id +'/list')
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    },

    async getMContacts() {
      try {
        this.mContacts = [];
        this.mContacts = await apiGet('/contact')
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    },

    handleMSelectionChange(val) {
      this.mContactsSelectable = val
      this.$forceUpdate()
    },

    async saveContactToGroup() {
      try {
        this.showAlert = false;
        this.showAlertContact=false;
        this.alertMessageContact ='';

        if(this.mContactsSelectable){
          this.customError.resetErrorBag();
         let contactIds = this.mContactsSelectable.map(contact => contact.id);
          try {
            let response = await apiPost('/contactGroupMember/add', { contactGroupId: this.id, contactIds: contactIds })
            this.alertMessageContact = this.customError.formatContactsResponse(response);
            this.contactModal = false
            this.showAlertContact=true;
            this.getGroupContacts();
          } catch (error) {
            this.customError.transformValidationErrors(error.response);
            console.error('Error saving contacts:', error);
          }
       }
      } catch (error) {
        this.alertMessage = error;
        this.showAlert = true
        console.error('Error saving contacts:', error);
      }
    },

    removeFromGroup: async function () {
      const removeContact = await this.$swal({
        title: 'Remove Contact?',
        text: 'Are you sure you would like to Remove selected contacts from group?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'Yes, Remove Selected Contacts!',
        cancelButtonText: 'Keep Contacts',
        buttonsStyling: false,
        customClass: {
          container: 'custom-swal',
          title: 'mb-0',
          icon: 'question-icon mt-0',
          cancelButton: 'my-0',
          confirmButton: 'my-0'
        }
      })
      if (removeContact.value) {
        this.showAlert = false;
        this.showAlertContact=false;
        this.alertMessageContact ='';

        if(this.contactSelectable){
        let contactIds = this.contactSelectable.map(contact => contact.id);
          try {
            let response = await apiPost('/contactGroupMember/delete', { contactGroupId: this.id, contactIds: contactIds })
            this.alertMessageContact = this.customError.formatContactsResponse(response);
            this.showAlertContact=true;
            this.getGroupContacts();

            //window.location.reload()
          } catch (error) {
            this.alertMessage = error;
            this.showAlert = true
            console.error('Error removing contact from group:', error);
          }
        }
      }
    },
    backToContactsGroups: function () {
      this.$router.push('/contacts-groups/')
    },
  }
}
</script>
<style scoped>
.action-button-icon {
  filter: invert(1);
  width: 20px;
}
</style>
