import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from '@auth0/auth0-vue';
import NotFound from '../pages/GeneralViews/NotFoundPage.vue'
import DashboardLayout from '../pages/Dashboard/Layout/DashboardLayout.vue'
import AuthLayout from '../pages/Dashboard/Pages/AuthLayout.vue'
import Content from '../pages/Dashboard/Layout/Content.vue'
import DefaultHeader from '../pages/Dashboard/DefaultHeader.vue'
import Dashboard from '../pages/Dashboard/Dashboard/Dashboard.vue'

import EditProfileForm from '../pages/Dashboard/Pages/UserProfile/EditProfileForm.vue'
import UserCard from '../pages/Dashboard/Pages/UserProfile/UserCard.vue'

import EventsViewAll from '../pages/Dashboard/Pages/Events/EventsViewAll.vue'
import EventsViewSingle from '../pages/Dashboard/Pages/Events/EventsViewSingle.vue'
import EventsCreate from '../pages/Dashboard/Pages/Events/EventsCreate.vue'
import EventsEdit from '../pages/Dashboard/Pages/Events/EventsEdit.vue'

import EventsPostMedia from '../pages/Dashboard/Pages/Events/EventsPostMedia.vue'
import EventsViewMedia from '../pages/Dashboard/Pages/Events/EventsViewMedia.vue'

import LocationViewAll from '../pages/Dashboard/Pages/Locations/LocationViewAll.vue'
import LocationViewSingle from '../pages/Dashboard/Pages/Locations/LocationViewSingle.vue'
import LocationCreate from '../pages/Dashboard/Pages/Locations/LocationCreate.vue'
import LocationEdit from '../pages/Dashboard/Pages/Locations/LocationEdit.vue'

import ContactsViewAll from '../pages/Dashboard/Pages/Contacts/ContactsViewAll.vue'
import ContactsViewSingle from '../pages/Dashboard/Pages/Contacts/ContactsViewSingle.vue'
import ContactsCreate from '../pages/Dashboard/Pages/Contacts/ContactsCreate.vue'
import ContactsEdit from '../pages/Dashboard/Pages/Contacts/ContactsEdit.vue'

import ContactsGroupsViewAll from '../pages/Dashboard/Pages/ContactsGroups/ContactsGroupsViewAll.vue'
import ContactsGroupCreate from '../pages/Dashboard/Pages/ContactsGroups/ContactsGroupCreate.vue'
import ContactsGroupEdit from '../pages/Dashboard/Pages/ContactsGroups/ContactsGroupEdit.vue'
import ContactsGroupViewContacts from '../pages/Dashboard/Pages/ContactsGroups/ContactsGroupViewContacts.vue'

import InvitesViewReceived from '../pages/Dashboard/Pages/Invites/InvitesViewReceived.vue'
import InvitesViewSent from '../pages/Dashboard/Pages/Invites/InvitesViewSent.vue'
import InvitesViewSingle from '../pages/Dashboard/Pages/Invites/InvitesViewSingle.vue'
import InvitesViewSingleEmail from '../pages/Dashboard/Pages/Invites/InvitesViewSingleEmail.vue'
import InvitesCreate from '../pages/Dashboard/Pages/Invites/InvitesCreate.vue'

import Home from '../pages/Dashboard/Pages/Home.vue'
import Ping from '../pages/Auth/Ping.vue'
// import Login from '../pages/Auth/Login.vue'
import VerifyEmail from '../pages/Auth/VerifyEmail.vue'
import Logout from '../pages/Auth/Logout.vue'
import Terms from '../pages/Dashboard/Pages/TermsConditions.vue'
import Privacy from '../pages/Dashboard/Pages/PrivacyPolicy.vue'
import AboutUs from '../pages/Dashboard/Pages/AboutUs.vue'
import LearnMore from '../pages/Dashboard/Pages/LearnMore.vue'
import Investors from '../pages/Dashboard/Pages/Investors.vue'
import Callback from '../pages/Auth/Callback.vue'

import Register from "../pages/Dashboard/Pages/Pwdl-Login.vue";
import Login from "../pages/Dashboard/Pages/Pwdl-Login.vue";
import ForgotPassword from "../pages/Dashboard/Pages/Vnud-Forgot-Password.vue";
import ResetPassword from "../pages/Auth/reset-password.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresLogin: false },
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'Home index',
        component: Home
      }
    ]
  },
  {
    path: '/ping',
    name: 'Ping',
    meta: { requiresLogin: false },
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'Ping index',
        component: Ping
      }
    ]
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'Privacy index',
        component: Privacy
      }
    ]
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'Terms index',
        component: Terms
      }
    ]
  },
  {
    path: '/learn-more',
    name: 'LearnMore',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'LearnMore index',
        component: LearnMore
      }
    ]
  },
  {
    path: '/investors',
    name: 'Investors',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'Investors index',
        component: Investors
      }
    ]
  },
  {
    path: '/about',
    name: 'AboutUs',
    meta: { requiresLogin: false },
    component: Content,
    children: [
      {
        path: '',
        name: 'AboutUs index',
        component: AboutUs
      }
    ]
  },
  {
    path: '/auth/',
    name: 'Auth',
    meta: { requiresLogin: false },
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        components: { default: Login, header: DefaultHeader }
      },
      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: 'verify-email',
        name: 'VerifyEmail',
        components: { default: VerifyEmail, header: DefaultHeader }
      },

      {
        path: 'logout',
        name: 'Logout',
        components: { default: Logout, header: DefaultHeader }
      },
      {
        path: 'callback',
        name: 'Callback',
        components: { default: Callback, header: DefaultHeader }
    },
    ]
  },
  {
    path: '/',
    meta: { requiresLogin: false },
    component: DashboardLayout,
    children: [
      {
        path: 'invites/email/:uuid',
        name: 'View Email Event Invite',
        components: { default: InvitesViewSingleEmail, header: DefaultHeader }
      }
    ]
  },
  {
    path: '/',
    // meta: { requiresLogin: true },
    component: DashboardLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard, header: DefaultHeader }
      },

      {
        path: 'events',
        name: 'Events',
        components: { default: EventsViewAll, header: DefaultHeader }
      },
      {
        path: 'events/new',
        name: 'Create Event',
        components: { default: EventsCreate, header: DefaultHeader }
      },
      {
        path: 'events/:eventId/edit',
        name: 'Edit Event',
        components: { default: EventsEdit, header: DefaultHeader }
      },
      {
        path: 'events/:eventId/upload',
        name: 'Upload Event Media',
        components: { default: EventsPostMedia, header: DefaultHeader }
      },
      {
        path: 'events/media/:mediaId',
        name: 'View Event Media',
        components: { default: EventsViewMedia, header: DefaultHeader }
      },
      {
        path: 'events/:eventId',
        name: 'View Event',
        components: { default: EventsViewSingle, header: DefaultHeader }
      },
      {
        path: 'invites/received',
        name: 'Invites Received',
        components: { default: InvitesViewReceived, header: DefaultHeader }
      },
      {
        path: 'invites/sent',
        name: 'Invites sent',
        components: { default: InvitesViewSent, header: DefaultHeader }
      },
      {
        path: 'invites/new',
        name: 'Send Event Invite',
        components: { default: InvitesCreate, header: DefaultHeader }
      },
      {
        path: 'invites/:inviteId',
        name: 'View Event Invite',
        components: { default: InvitesViewSingle, header: DefaultHeader }
      },
      {
        path: 'profile',
        name: 'Profile',
        components: { default: UserCard, header: DefaultHeader }
      },
      {
        path: 'profile/edit',
        name: 'Edit Profile',
        components: { default: EditProfileForm, header: DefaultHeader }
      },
      {
        path: 'settings',
        name: 'Settings',
        components: { default: Dashboard, header: DefaultHeader }
      },
      {
        path: 'contacts',
        name: 'View Contacts',
        components: { default: ContactsViewAll, header: DefaultHeader }
      },
      {
        path: 'contacts/new',
        name: 'Create Contact',
        components: { default: ContactsCreate, header: DefaultHeader }
      },
      {
        path: 'contacts/:contactId',
        name: 'View Contact',
        components: { default: ContactsViewSingle, header: DefaultHeader }
      },
      {
        path: 'contacts/:contactId/edit',
        name: 'Edit Contact',
        components: { default: ContactsEdit, header: DefaultHeader }
      },
      {
        path: 'contacts-groups',
        name: 'View Contacts Groups',
        components: { default: ContactsGroupsViewAll, header: DefaultHeader }
      },
      {
        path: 'contacts-groups/:groupId/edit',
        name: 'Edit Contacts Group',
        components: { default: ContactsGroupEdit, header: DefaultHeader }
      },
      {
        path: 'contacts-groups/:groupId/view-group-contacts',
        name: 'View Group Contacts ',
        components: { default: ContactsGroupViewContacts, header: DefaultHeader }
      },
      {
        path: 'contacts-groups/new',
        name: 'Create Contacts Group',
        components: { default: ContactsGroupCreate, header: DefaultHeader }
      },
      {
        path: 'locations',
        name: 'Locations',
        components: { default: LocationViewAll, header: DefaultHeader }
      },
      {
        path: 'locations/new',
        name: 'Create Location',
        components: { default: LocationCreate, header: DefaultHeader }
      },
      {
        path: 'locations/:locationId',
        name: 'View Location',
        components: { default: LocationViewSingle, header: DefaultHeader }
      },
      {
        path: 'locations/:locationId/edit',
        name: 'Edit Location',
        components: { default: LocationEdit, header: DefaultHeader }
      }
    ]
  },
  {
    path: '/:catchAll(.*)', // Use catchAll for 404 page
    name: 'NotFound',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: NotFound
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

export default router
