<template>
  <div>
    <div class="contact-box">
      <vnud-card card-body-classes="table-full-width" no-footer-line>
        <template v-slot:header>
          <div class="d-md-flex align-items-center justify-content-between">
            <div>
              <h4 class="card-title">Contacts</h4>
              <div>{{ 'Found ' + contacts.length + ' contacts' }}</div>
            </div>
            <div class="d-sm-flex align-items-center justify-content-between">
              <div class="d-sm-flex align-items-center mt-md-0 mt-2">
                <fg-input class="">
                  <el-select v-model="selectedContactGroup" @change="searchFilter()" clearable class="custom-select-rounded mr-2"
                    placeholder="Select Group" :remote-method="searchContact" :loading="contactsLoading">
                    <el-option v-for="group in contactGroupOptions" :key="group.id" :label="group.name"
                      :value="group.id">
                    </el-option>
                  </el-select>
                </fg-input>
                <fg-input class="">
                  <el-input v-model="searchQuery" @input="handleSearchInput" type="search" class="input-with-icon mx-sm-2 contact-search" clearable
                    placeholder="Search records" aria-controls="datatables">
                    <template #prefix>
                      <el-icon class="el-input__icon">
                        <search />
                      </el-icon>
                    </template>
                  </el-input>
                </fg-input>
              </div>
              <div class="d-flex align-items-center">
                <el-tooltip content="Sync Contact" :open-delay="300" placement="top">
                  <n-button :disabled="!IsGooglUser" type="primary" class="mt-0" round icon="" size="lg"
                    style="float: right;" @click="opengContactModal">
                    <i class="now-ui-icons arrows-1_refresh-69 text-white"></i>
                  </n-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
        <div v-if="loading" class="mt-3 text-center">
          <v-progress-circular indeterminate class="spinner"></v-progress-circular>
        </div>
        <el-table stripe style="width: 100%" :data="contacts">
          <el-table-column min-width="150" prop="name" label="Name" />
          <el-table-column min-width="200" prop="email" label="Email Address" class-name="text-ellipsis" />
          <el-table-column min-width="110" prop="phone" label="Phone" />
          <el-table-column min-width="200" header-align="right" label="Actions">
            <template v-slot="{ row }">
              <div class="text-right table-actions">
                <el-tooltip content="View" :open-delay="300" placement="top">
                  <n-button type="info" size="sm" @click="goToContact(row.id)">
                    <i class="fa-regular fa-eye text-white"></i>
                  </n-button>
                </el-tooltip>
                <el-tooltip content="Edit" :open-delay="300" placement="top">
                  <n-button type="info" size="sm" @click="editContact(row.id)">
                    <i class="fa-solid fa-pen-to-square text-white"></i>
                  </n-button>
                </el-tooltip>
                <el-tooltip content="Delete" :open-delay="300" placement="top">
                  <n-button type="info" size="sm" @click="deleteContact(row.id)">
                    <i class="fa-solid fa-rectangle-xmark text-white"></i>
                  </n-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <template v-slot:footer>
          <n-pagination class="pagination-no-border" :total="pagination.totalRecords" :perPage="pagination.perPage"
            v-model="pagination.currentPage" />
          <div class="d-flex contact-footer justify-content-between flex-wrap">
            <div class="d-flex align-items-center">
              <span class="mr-2">Contacts per page: </span>
              <el-select @change="searchFilter()" class="per-page-selector" placeholder="Contacts per page" v-model="pagination.perPage">
                <el-option v-for="option in pagination.perPageOptions" :value="option" :label="option" :key="option" />
              </el-select>
            </div>
            <el-tooltip content="New Contact" :open-delay="300" placement="top">
              <n-button type="primary" round icon="" size="lg" style="float: right;" @click="addContact">
                <i class="now-ui-icons ui-1_simple-add text-white"></i>
              </n-button>
            </el-tooltip>
          </div>
        </template>
      </vnud-card>
    </div>
    <modal v-model:show="gContactModal" modalClasses="modal-lg" class="modal-primary google-contact-modal"
      :showClose="true">
      <template v-slot:header>
        <div class="modal-profile d-lg-flex justify-content-between align-items-center w-100">
          <h4 class="card-title mt-0">Google Contact</h4>
          <div class="heading d-flex align-items-center flex-start justify-content-between justify-content-lg-start">
            <div>
              <fg-input class="d-flex">
                <el-input type="search" class="input-with-icon mr-10" clearable placeholder="Search records"
                  v-model="gSearchQuery" aria-controls="datatables">
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <search />
                    </el-icon>
                  </template>
                </el-input>
              </fg-input>
            </div>
            <div>
              <el-tooltip content="Sync Contact" :open-delay="300" placement="top">
                <n-button type="primary" class="mt-0 syn-btn" round icon="" size="lg" @click="opengContactModal">
                  <i class="now-ui-icons arrows-1_refresh-69 text-white"></i>
                </n-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </template>
      <el-table stripe :data="gQueriedData" style="width: 100%" @selection-change="handleGSelectionChange">
        <el-table-column type="selection" width="55" :selectable="gselectable" />
        <el-table-column property="name" label="Name" width="120" />
        <el-table-column property="email" label="Email" />
        <el-table-column property="phone" label="Phone" />
      </el-table>
      <template v-slot:footer>
        <n-button @click="saveContact" type="default" :disabled="gContactSelectable == 0">Save</n-button>
        <n-button @click="saveAllContact" type="default">Save All</n-button>
        <div class="
              d-flex
              w-100
              justify-content-between
              flex-wrap
              align-items-center
              m-0
              py-3
            ">
          <div class="d-flex align-items-center">
            <span class="mr-2">Contacts per page: </span>
            <el-select class="per-page-selector" placeholder="Contacts per page" v-model="gPagination.perPage">
              <el-option v-for="option in gPagination.perPageOptions" :value="option" :label="option" :key="option" />
            </el-select>
          </div>
          <n-pagination class="pagination-no-border mb-0" v-model="gPagination.currentPage"
            :per-page="gPagination.perPage" :total="googleContact.length">
          </n-pagination>
        </div>
      </template>
    </modal>

    <modal v-model:show="showAlert" class="modal-primary" :show-close="false">
      <template v-slot:header>
        <div class="modal-profile d-flex justify-content-center align-items-center">
          <i class="now-ui-icons location_pin"></i>
        </div>
      </template>
      <p>{{ alertMessage }}</p>
      <template v-slot:footer>
        <n-button type="neutral" link="" @click="showAlert = false">Try again</n-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { apiGet, apiPost } from '@/utils/api'
import { Modal, Pagination as NPagination, VnudCard } from '@/components/index.js'
import { Search } from '@element-plus/icons-vue'
import { isGoogleUser } from '@/utils/auth.js'
export default {
  components: {
    VnudCard,
    NPagination,
    Search,
    Modal
  },
  data() {
    return {
      contacts: [],
      searchQuery: "",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        totalRecords: 0
      },
      propsToSearch: ["name"],
      gselectable: (row) => ![1, 2].includes(row.id),
      gContactModal: false,
      googleContact: [],
      gSearchQuery: '',
      gContactSelectable: [],
      gPagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      showAlert: false,
      alertMessage: '',
      loading: false,
      selectedContactGroup: '',
      contactGroupOptions: [],
    }
  },
  async mounted() {
    this.getContacts();
    this.getContactGroup();
  },
  watch: {
    currentPage() {
      this.getContacts();
    }
  },
  methods: {
    async getContactGroup() {
      this.contactGroupOptions = await apiGet('/contactGroup')
    },
    opengContactModal() {
      this.gContactModal = true
      this.syncContacts();
    },
    handleGSelectionChange(val) {
      this.gContactSelectable = val
      this.$forceUpdate()
    },
    handleSearchInput() {
      if (this.searchQuery.length >= 3 || this.searchQuery.length === 0) {
        this.searchFilter();
      }
    },
    searchFilter(){
      this.getContacts();
    },

    getSearchQuery() {
      const params = new URLSearchParams();
      if (this.searchQuery) {
        params.append('searchTerm', this.searchQuery);
      }
      if (this.selectedContactGroup) {
        params.append('groupId', this.selectedContactGroup);
      }
      if (this.pagination.currentPage) {
        params.append('page', this.pagination.currentPage);
      }
      if (this.pagination.perPage) {
        params.append('limit', this.pagination.perPage);
      }
      return `?${params.toString()}`;
    },

    async getContacts() {
      try {
        this.loading = true;
        this.contacts = [];
        let response  = await apiGet('/contact/search-by-groupId'+this.getSearchQuery())
        this.contacts= response.contacts;
        this.pagination.totalRecords = response.pagination.totalRecords
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
      finally {
        this.loading = false;
      }
    },

    async saveContact() {
      try {
        this.showAlert = false;
        let selectedContacts = this.gContactSelectable.map(contact => {
          const name = contact.name != 'Unknown' ? contact.name : null;
          const email = contact.email != 'No Email' ? contact.email : null;
          const phone = contact.phone != 'No Phone' ? contact.phone : null;
          return {
            name,
            email,
            phone
          };
        });
        const response = await apiPost('/contact/save-google-contacts', {
          contacts: selectedContacts
        });
        this.alertMessage = response.message;
        this.showAlert = true
        this.getContacts();
      } catch (error) {
        this.alertMessage = error;
        this.showAlert = true
        console.error('Error saving contacts:', error);
      }
    },
    saveAllContact() {
      this.gContactSelectable = this.googleContact;
      this.saveContact();
    },
    async syncContacts() {
      try {
        this.showAlert = false;
        this.loading = true;
        const response = await apiPost('/contact/sync-google-contacts');
        this.googleContact = response.contacts.map(contact => {
          const name = contact.names ? contact.names[0].displayName : 'Unknown';
          const email = contact.emailAddresses ? contact.emailAddresses[0].value : 'No Email';
          const phone = contact.phoneNumbers ? contact.phoneNumbers[0].value : 'No Phone';
          return {
            name,
            email,
            phone
          };
        });
        this.loading = false;
      } catch (error) {
        this.alertMessage = 'Error fetching contacts';
        this.showAlert = true
        this.loading = false;
        console.error('Error fetching contacts:', error);
      }
    },

    goToContact(id) {
      this.$router.push('/contacts/' + id)
    },
    // Navigate to the add contact page
    addContact() {
      this.$router.push('/contacts/new')
    },
    // Navigate to the edit contact page
    editContact(id) {
      this.$router.push('/contacts/' + id + '/edit')
    },
    // Delete the contact with confirmation
    async deleteContact(id) {
      const deleteContact = await this.$swal({
        title: 'Delete Contact?',
        text: 'Are you sure you would like to delete this contact?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-danger btn-fill',
        cancelButtonClass: 'btn btn-success btn-fill',
        confirmButtonText: 'Yes, Delete Contact!',
        cancelButtonText: 'Keep Contact',
        buttonsStyling: false,
        customClass: {
          container: 'custom-swal',
          title: 'mb-0',
          icon: 'question-icon mt-0',
          cancelButton: 'my-0',
          confirmButton: 'my-0'
        }
      })
      if (deleteContact.value) {
        await apiPost('/contact/' + id + '/delete')
        // Reload the current page to refresh the contacts list
        window.location.reload()
      }
    },
  },
  computed: {
    IsGooglUser() {
      return isGoogleUser()
    },
    currentPage() {
      return this.pagination.currentPage
    },
    gPagedContacts() {
      return this.googleContact.slice(this.from, this.to);
    },
    gQueriedData() {
      if (!this.gSearchQuery) {
        return this.gPagedContacts;
      }
      let result = this.googleContact.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toLowerCase();
          if (rowValue.includes && rowValue.includes(this.gSearchQuery.toLowerCase())) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.gPagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.gPagination.perPage * (this.gPagination.currentPage - 1);
    },
    total() {
      return this.googleContact.length > 0
        ? this.googleContact.length
        : this.googleContact.length;
    },
  },
}
</script>

<style>
.contact-box {
  max-width: 800px;
}

.text-ellipsis .cell {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.google-contact-modal .per-page-selector,
.contact-footer .per-page-selector {
  width: 100px;
}

.input-with-icon.el-input--prefix .el-input__inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
}

.input-with-icon .el-input__wrapper {
  border-radius: 50px !important;
}

.input-with-icon .el-input__inner:focus {
  border: 0 !important;
}

.google-contact-modal .close {
  color: rgb(44, 44, 44) !important;
  opacity: 1;
}

.google-contact-modal .modal-content .modal-header {
  justify-content: space-between;
}

.modal-profile .heading {
  margin-right: 40px;
}

.modal-profile .heading .syn-btn {
  position: relative !important;
  top: 0;
  right: 0;
  left: 0;
  margin-left: 10px;
}

@media(max-width: 991px) {
  .modal-profile .heading {
    margin-right: 0;
  }

  .google-contact-modal .el-table .cell {
    font-size: 17px;
  }
}
</style>
