import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import DashboardPlugin from './dashboard-plugin.js'
import VueGravatar from 'vue3-gravatar'
// import { userHasValidLogin } from '@/utils/auth.js'
import customErrorPlugin from '@/utils/customErrorPlugin';

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import { plugin as vueTransitionsPlugin } from '@morev/vue-transitions';
import '@morev/vue-transitions/styles';

import { createAuth0 } from '@auth0/auth0-vue';

const vuetify = createVuetify({
  components,
  directives
})

window.global = window.global || window

const app = createApp(App)
app.config.devtools = true

app.use(
  createAuth0({
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_APP_AUTH0_CLIENTID,
    authorizationParams: {
      redirect_uri: window.location.origin + '/auth/callback'
    }
  })
);

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresLogin)) {
//     if (!userHasValidLogin()) {
//       next({ path: '/auth/login' })
//     }
//   }
//   next()
// })

app.use(router)
app.use(ElementPlus)
app.use(DashboardPlugin)
app.use(vuetify)
app.use(VueSweetalert2)
app.use(VueGravatar)
app.use(vueTransitionsPlugin({
  defaultProps: {
    duration: 500,
  },
  componentDefaultProps: {
    TransitionExpand: {
      duration: 500,
    }
  }
}));
app.use(customErrorPlugin);

app.mount('#app')

/* We import element-ui variables at the end so they can override the default element-ui colors */
import './assets/css/vuetify.css'
import './assets/css/material.css'
import './assets/css/custom-style.scss'
