<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xl-4">
        <vnud-card class="stacked-form" title="Send new invite">
          <template v-slot:header>
            <h2 class="card-title">Send new Invite</h2>
          </template>
          <div v-if="eventFromQuery">
            <div v-if="isHost" style="float: right">
              <img style="cursor:pointer" @click="goEdit" width="20"
                src="@/assets/img/glyphicons-halflings-74-square-edit.svg" />
            </div>
            <h5>Selected Event</h5>
            <h6>{{ event.name }}</h6>
            <div>
              <strong>Starting on:</strong> {{ formatDate(event.start) }}
            </div>
            <div v-if="event.end">
              <strong>Ending on:</strong> {{ formatDate(event.end) }}
            </div>
            <div>
              <strong>Description:</strong> {{ event.description }}
            </div>
          </div>
          <div v-else>
            <h5>Select Event</h5>
            <div>
              <fg-input v-model="eventSearch" @keyup="searchEvent" label="Event" placeholder="Enter the event's name" />
              <radio name="event" v-model="eventSelection" v-for="event in events" :key="event.id"
                :label="'' + event.id">
                {{ event.name }}
              </radio>
            </div>
          </div>
          <br>
          <hr><br>
          <form method="#" action="#" @submit.prevent>
            <div>
              <h5>Invite To Contacts Groups</h5>
              <fg-input>
                <el-select class="invite-select" v-model="contactGroupSelection" filterable clearable remote remote-show-suffix reserve-keyword
                  placeholder="Enter Contract Group Name to Search" :remote-method="searchContactGroup" :loading="contactsGroupsLoading">
                  <el-option v-for="group in contactsGroups" :key="group.id" :label="group.name" :value="group.id">
                  </el-option>
                </el-select>
              </fg-input>
              <n-button @click="inviteContactGroup" class="btn-info text-white">Send Invite to Group</n-button>
            </div>
            <br>or<br><br>
            <div>
              <h5>Invite Existing Contacts</h5>
              <fg-input>
                <el-select class="invite-select" v-model="contactSelection" multiple filterable remote reserve-keyword
                  placeholder="Enter Contact's Name" :remote-method="searchContact" :loading="contactsLoading">
                  <el-option v-for="contact in contacts" :key="contact.id" :label="contact.name" :value="contact.id">
                  </el-option>
                </el-select>
              </fg-input>
              <n-button @click="inviteContact" class="btn-info text-white">Send Invite</n-button>
            </div>
            <br>or<br><br>
            <div>
              <h5>Invite New Contact</h5>
              <Alert :error-bag="customError.errorBag" class="mb-2" />
              <fg-input v-model="name" label="Name" placeholder="Enter the contact's name" required />
              <fg-input v-model="email" label="Email" placeholder="Enter the contact's email" required />
              <fg-input v-model="phone" label="Phone" placeholder="Enter the contact's phone" required />
              <n-button @click="createAndInviteContact" class="btn-info text-white">Create &amp; Invite</n-button>
            </div>
          </form>
        </vnud-card>
      </div>
    </div>
    <modal :show="showAlert" class="modal-primary" :show-close="false">
      <template v-slot:header>
        <div class="modal-profile d-flex justify-content-center align-items-center">
          <i class="now-ui-icons location_pin"></i>
        </div>
      </template>
      <p>Please enter all required Contact data</p>
      <template v-slot:footer>
        <el-button type="neutral" @click="showAlert = false">Try again</el-button>
      </template>
    </modal>
  </div>
</template>
<script>
import moment from 'moment'
import { Modal, VnudCard, Radio } from '@/components/index'
import { apiGet, apiPost } from '@/utils/api.js'
import Alert from "@/components/Alert.vue";
export default {
  components: {
    Radio,
    Modal,
    VnudCard,
    Alert
  },
  inject: ["customError"],
  data() {
    return {
      event: {},
      events: [],
      eventSearch: '',
      eventSelection: null,
      eventFromQuery: false,
      contact: null,
      contacts: [],
      contactsLoading: false,
      contactSelection: [],
      contactGroupSelection:null,
      contactsGroups:[],
      contactsGroupsLoading:false,
      name: '',
      email: '',
      phone: '',
      showAlert: false,
      isHost: false,
    }
  },
  async created() {
    this.customError.resetErrorBag();
    if (this.$route.query.eventId) {
      this.eventSelection = this.$route.query.eventId
      let event = await apiGet('/event/' + this.eventSelection)
      if (event) {
        this.event = event
        this.eventFromQuery = true
        this.isHost = (await apiPost('/attendee/host/' + event.id)).isHost
      } else {
        this.eventSelection = null
        this.eventFromQuery = false
      }
    }
  },
  methods: {
    // Format the date using moment.js
    formatDate(date) {
      return moment.utc(date).format("ddd, MMM D, YYYY @ h:mm a");
    },

    searchContactGroup: async function (contactGroupSearch) {
      if (!contactGroupSearch || !(contactGroupSearch.trim().length)) {
        this.contactsGroups = []
        this.contactsGroupsLoading = false
      } else {
        this.contactsGroupsLoading = true
        this.contactsGroups = await apiPost('/contactGroup/search', { name: contactGroupSearch })
        this.contactsGroupsLoading = false
      }
    },
    inviteContactGroup:async function(){
      if (!this.contactGroupSelection) {
        this.showAlert = true
      } else {
        await apiPost('/attendee/invite-contact-group', { eventId: this.eventSelection, contactGroupId: this.contactGroupSelection })
        this.$router.push('/events/' + this.eventSelection + '?section=attendees')
      }
    },

    searchEvent: async function () {
      if (!this.eventSearch || !(this.eventSearch.trim().length)) {
        this.events = []
      } else {
        this.events = await apiPost('/event/search', { keyword: this.eventSearch })
      }
    },
    searchContact: async function (contactSearch) {
      if (!contactSearch || !(contactSearch.trim().length)) {
        this.contacts = []
        this.contactsLoading = false
      } else {
        this.contactsLoading = true
        this.contacts = await apiPost('/contact/search', { name: contactSearch })
        this.contactsLoading = false
      }
    },
    createAndInviteContact: async function () {
      this.customError.resetErrorBag();
      if (!this.name || !this.email || !this.eventSelection) {
        this.showAlert = true
      } else {
        try {
          let newContact = await apiPost('/contact', {
            name: this.name,
            email: this.email,
            phone: this.phone,
          })
          this.contactSelection = [newContact.id]
          this.inviteContact()
        } catch (error) {
          this.customError.transformValidationErrors(error.response);
        }
      }
    },
    inviteContact: async function () {
      if (!this.contactSelection.length || !this.eventSelection) {
        this.showAlert = true
      } else {
        await apiPost('/attendee', { eventId: this.eventSelection, contactId: this.contactSelection })
        this.$router.push('/events/' + this.eventSelection + '?section=attendees')
      }
    },
    goEdit() {
      this.$router.push('/events/' + this.event.id + '/edit')
    }
  }
}
</script>
<style lang="scss">
.el-select .el-tag {
  background-color: #204065 !important;
  padding: 4px 8px;
}

.el-select__tags {
  padding: 15px;
}

.invite-select {
  .el-select__wrapper {
    padding: 7px 18px;
    border-radius: 50px;

    .el-select__placeholder {
      span {
        opacity: 0.5;
        font-size: 0.8571em;
      }
    }
  }
}
</style>
