<template>
  <p id="messageBox">{{ message }}</p>
</template>

<script>
import { apiGet } from '@/utils/api'
import { setToken, setApiUser } from '@/utils/auth'

export default {
  data: function(){
    return {
      message: 'Signing in...'
    }
  },
  async mounted () {
    const token = await this.$auth0.getAccessTokenSilently();

    if (!token) {
      this.message = 'Something happened with the Sign In request'
    } else {
      setToken(token)
      setApiUser(await apiGet('/user'))
      this.$router.push('/dashboard')
    }
  }
}
</script>
