<template>
  <div>
    <navbar :show-navbar="showMenu" class="px-4 py-2" id="nav">
      <div class="navbar-wrapper">
        <div class="navbar-toggle" :class="{ toggled: showMenu }">
          <navbar-toggle-button @click="toggleNavbar" target="#nav">
          </navbar-toggle-button>
        </div>
        <a class="navbar-brand d-flex align-items-center auth-logo" href="/">
          <img src="https://instaevent.imgix.net/files/instaevent-logo-large.png?w=125" alt="InstaEvent" title="Instantly create events" >
          InstaEvent
        </a>
      </div>

      <template v-slot:navbar-menu>
        <router-link
          to="/auth/register"
          v-slot="{ href, isActive, isExactActive }"
          custom
        >
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a :href="href" class="nav-link">
              <i class="now-ui-icons tech_mobile"></i>
              Register
            </a>
          </li>
        </router-link>

        <router-link
          to="/auth/login"
          v-slot="{ href, isActive, isExactActive }"
          custom
        >
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a :href="href" class="nav-link">
              <i class="now-ui-icons users_circle-08"></i>
              Login
            </a>
          </li>
        </router-link>
      </template>
    </navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page section-image" :class="pageClass" filter-color="black" data-image="/img/bg17.jpg">
        <div class="content">
          <div class="container">
            <RouterView />
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <nav>
              <ul>
                <li>
                  <a href="/learn-more">
                    Learn More
                  </a>
                </li>
                <li>
                  <a href="/about">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/investors">
                    Investors
                  </a>
                </li>
              </ul>
            </nav>

            <div class="copyright"><a href="/privacy-policy">Privacy Policy</a> - <a href="/terms-and-conditions">Terms
                and
                Services</a> -<a href="/copyright-policy"> &copy; {{ year }}</a>
            </div>
          </div>
        </footer>

        <div class="full-page-background" :style="imageStyles"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Navbar, NavbarToggleButton } from '@/components/index.js';

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    }
  },
  computed: {
    imageStyles() {
      let img = new URL('@/assets/img/bg13.jpg', import.meta.url).href;
      return {
        backgroundImage: `url(${img})`
      }
    }
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
      this.showMenu = !this.showMenu
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      this.showMenu = false
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  },
  beforeUnmount() {
    this.closeMenu()
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next()
      }, this.menuTransitionDuration)
    } else {
      next()
    }
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  }
}
</script>
<style lang="scss">
.auth-logo {
  font-size: 1rem !important;
  img {
    width: 34px;
    margin-right: 9px
  }
}

$scaleSize: .8;

@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  100% {
    opacity: 1;
  }
}

.copyright a {
  font-size: 0.8571em;
  text-transform: uppercase;
  text-decoration: none;
}
.dashboard-footer .copyright a {
  color: #222 !important;
}
.beta {
  margin: 0px auto;
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
